body {
  background: linear-gradient(to right, rgba(12, 12, 14, 0.7), rgba(13, 13, 15, 0.7)), url(./img/body.jpg) center center no-repeat;
  background-size: cover !important;
  overflow-x: hidden !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-size: auto; */
}

code {
  font-family: Roboto, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pulse {
  -webkit-animation: radial-pulse 2s infinite;
  animation: radial-pulse 2s infinite;
}

.socketOnline {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px;
  background-color: red;
  border-radius: 50vh;
}

.socketOnline.active {
  background-color: #59ad56;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 53.3%, 0.4117647058823529);
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 94.5%, 0);
}

@keyframes radial-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0 0 0 40px rgba(0, 0, 0, 0);
  }
}
